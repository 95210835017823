import React, { useContext, useEffect, useRef, useState } from "react";
import favorito from "../../../images/menu/favorito.png";
import favoritoActive from "../../../images/menu/resaltado.png";
import derecha from "../../../images/menu/derecha.png";
import Header from "../../includes/Header/Header";
import Menu from "../../includes/Menu/Menu";
import "./applicants.css";
import curriculum from "../../../images/menu/curriculum.png";
import { API } from "../../../http-common";
import { Link, useHistory } from "react-router-dom";
import $ from "jquery";
import { useSnackbar } from "react-simple-snackbar";
import { FILE_SIZE_LIMIT, PRODUCTION_URL } from "../../../constants";
import ModalInfoApplicant from "./ModalInfoApplicant/ModalInfoApplicant";
import ModalProcessApplicant from "./ModalProcessApplicant/ModalProcessApplicant";
import ModalNotes from "./ModalNotes/ModalNotes";
import { UserContext } from "../../../context/userContext";
import * as Constants from "../../../constants";
import AccessError from "../../Error/AccessError/AccessError";




const ApplicantInfo = (req) => {
  let history = useHistory();
  const options = {
    style: {
      backgroundColor: "#c60000",
      color: "white",
      borderRadius: "10px",
      fontSize: "18px",
      textAlign: "center",
    },
    closeStyle: {
      fontSize: "16px",
    },
  };

  const [open] = useSnackbar(options);

  const { userData} = useContext(UserContext);
  const [data, setData] = useState([]);
  const [id, setId] = useState();
  const [idSearch, setIdSearch] = useState("");
  const [candidateId, setCandidateId] = useState("");
  const [name, setName] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [title, setTitle] = useState("");
  const [dni, setDni] = useState("");
  const [lastPosition, setLastPosition] = useState("");
  const [university, setUniversity] = useState("");
  const [email, setEmail] = useState("");
  const [experience, setExperience] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [lastJobTitle, setLastJobTitle] = useState("");
  const [business, setBusiness] = useState("");
  const [birthday, setBirthday] = useState("");
  const [personInCharge, setPersonInCharge] = useState("");
  const [education, setEducation] = useState("");
  const [linkCurriculum, setLinkCurriculum] = useState("");
  const [outstanding, setOutstanding] = useState(0);
  const [status, setStatus] = useState("");
  const [changeSearch, setChangeSearch] = useState("0");
  const [applicationId, setApplicationId] = useState(0);
  const [profileId, setProfileId] = useState(0);
  const [allData, setAllData] = useState([]);
  const [modalNotes, setModalNotes] = useState(false);
  const [typeNotes, setTypeNotes] = useState('');
  const [itemSelected, setItemSelected] = useState('');
  const [countNotes, setCountNotes] = useState(0);
  const [listUniversity,setListUniversity] = useState([])
  const [isLoading, setIsLoading] = useState(false);

  const [error, setError] = useState({error:0, message:""});
  const file = useRef(null);

  const [nameFile, setNameFile] = useState("");
  const [selectedFile, setSelectedFile] = useState("");
  // const [selectedFileError, setSelectedFileError] = useState(false)

  const [modalShow, setModalShow] = useState(false);
  const [modalProcessShow, setModalProcessShow] = useState(false);

  const [searches, setSearches] = useState([]);

  const getSearches = () => {
    API.get("/positions/opened/" + req.match.params.id).then(function (
      response
    ) {
      if (response.data.error === 0) {
        setSearches(response.data.data);
      } else {
        //todo
      }
    });
  };
  
const getListUniversity = () => {
  try {
    API.post("/candidates/university", { isEdit: true })
    .then(function (response) {
      if (response.data.error === 0) {
      let list = response.data.data
      setListUniversity(list)
      }});
  }
  catch {
    setListUniversity([])
   throw new Error("Error al cargar la lista de universidades")
  }
}
const handleShow = () => {
  getListUniversity()
}
  const setResponseData = (data) => {
    data = data.filter((d, i) => {
      d.destacado = 0;
      if(i === 0) d.destacado = 1;
      return d;
    })
    setAllData(data);
  }

  function getCountNotes() {
    API.get("candidates/"+req.match.params.id+"/count-notes").then(function (
        response
      ) {
        setCountNotes(response.data.notes)
      });
  }

  const getData = async (e) => {
    API.get("/candidates/" + req.match.params.id + '?applications=true')
    .then(function (response) {
      if(response.data.error === 0) {
        setResponseData(response.data.data.applications)
        setId(response.data.data.id);
        setIdSearch(response.data.data.id_search);
        setApplicationId(response.data.data.applicant_id);
        setName(response.data.data.username);
        setFirstName(response.data.data.first_name);
        setLastName(response.data.data.last_name);
        setTitle(response.data.data.title);
        setDni(response.data.data.dni);
        setUniversity(response.data.data.university);
        setEmail(response.data.data.email);
        setExperience(response.data.data.experience);
        setPhoneNumber(response.data.data.phone_number);
        setLastJobTitle(response.data.data.last_job_title);
        setBusiness(response.data.data.business);
        setLastPosition(response.data.data.position_name);
        setCandidateId(response.data.data.candidate_id)
        setPersonInCharge(response.data.data.person_in_charge);
        setEducation(response.data.data.education);
        setOutstanding(response.data.data.outstanding);
        setStatus(response.data.data.status);
        setProfileId(response.data.data.profile_id);
        if (response.data.data.curriculum?.length > 0) {
          setLinkCurriculum(
            `${PRODUCTION_URL}/uploads/${response.data.data.curriculum}`
          );
        } else {
          setLinkCurriculum("#");
        }
        let date = new Date(response.data.data.birthday);
        let birthday =
          (date.getUTCDate() < 10
            ? "0" + date.getUTCDate()
            : date.getUTCDate()) +
          "/" +
          (date.getUTCMonth() + 1) +
          "/" +
          date.getUTCFullYear();
        setBirthday(birthday);
        // if (status === '2') {
        getSearches();
        // }
        setData(response.data.data);
      } else {
        setError({error: response.data.error, message: response.data.message});
      }
    }).catch(function (error) {
      if (error.response && error.response.status === 403) {
        setError({error: 1, message: "Acceso denegado. No tienes permiso para acceder a estos datos."});
      } else {
        setError({error: 1, message: "Ocurrió un error inesperado."});
      }
    });
  };
  
  const onDataChange = () => {
    API.get('candidates/'+req.match.params.id+'/applications').then(function (response) {
      if (response.status === 200) {
        setResponseData(response.data)
      } else {
        // TODO
      }
    });
  }

  const checkvalue = (nameInput, value) => {
    if (nameInput === "selectedFile") {
      setSelectedFile(value);
      setNameFile(value.name);
    }
  };



  const sendData = () => {
    let dataForm = {};

    if (changeSearch !== "0" && changeSearch !== idSearch.toString()) {
      dataForm.search_id = changeSearch;
    }

    const formData = new FormData();

    if (selectedFile !== "") {
      const ext = selectedFile.name.substr(selectedFile.name.lastIndexOf("."));
      const fileName =
        `CANDIDATO_BUSQUEDA_${idSearch}_${dni}_${firstName}_${lastName}${ext}`
          .split(" ")
          .join("_")
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "");
      formData.append("selectedFile", selectedFile, fileName);
      
      dataForm.curriculum = fileName;
    }

    if(Object.keys(dataForm).length) {
      setIsLoading(true)
      try {
       
        API.put("/candidates/" + candidateId + "/current-application", dataForm)
        .then((response) => {

          if(dataForm.hasOwnProperty('curriculum')) {
            // Petición para el envío de documento
            API.post("/applications/file", formData)
                .then((response) => {
                })
                .catch((error) => {
                });

          }
          
          history.push("/candidatos");

        })
      }
      catch(error) {
        console.log(error)
        setIsLoading(false)
        
    }
  }
  };

  const reactivateSearch = (query = '') => {
    API.post("/applications/timeline/"+itemSelected.applicant_id+"/reactivate"+query)
      .then((response) => {
        // Petición para el envío de documento
        setResponseData(response.data.applications)
        $("#reactivar_proceso").modal("hide");
        getData()
      })
      .catch((error) => {
         throw new Error(error)
      });
      
  }
  



  const checkEmpty = () => {
    if (selectedFile !== "" || changeSearch !== "0") {
      $("#asignar-cambio-otra-busqueda").modal("show");
    } else {
      if (selectedFile.size / 1048576 > FILE_SIZE_LIMIT) {
        return open(`El archivo adjunto debe ser menor a ${FILE_SIZE_LIMIT}MB`);
      }
      return open("Debes asignar un cambio");
    }
  };


  const ReactivarProceso = (item) => {
    setItemSelected(item)
    $("#reactivar_proceso").modal("show");
  }


  const handleClick = (e) => {
    file.current.click();
  };

  const toggleApprove = () => {
    setOutstanding(!outstanding);
    API.put("/candidates/toggle_approve", {
      value: outstanding === 0 ? 1 : 0,
      id_user: req.match.params.id,
    });
  };

  useEffect(() => {
    getCountNotes()
    if (!modalShow) {
      getData();
    }
    handleShow()
  }, [modalShow]);

  const handleProcessModal = (item) => {
      setModalProcessShow(true);
      setApplicationId(item.applicant_id);   
  }

  return (
    <>
      <Header />
      <Menu />
      {
        
        error.error ? <AccessError errorMessage={error.message} buttonLink='/candidatos'/> : 
        (
        <div className="content-formulario-datos">
          <span className="volver">
            <img src={derecha} alt="" />
            <Link to="/candidatos">VOLVER AL LISTADO</Link>
          </span>
          <div className="formulario newapplicant">
            <div className="form">
              <span className="last_job">
                Ultima postulacion:{" "}
                <b>
                  {idSearch} - {lastPosition}
                </b>
              </span>
              <div
                className="section-selector"
                title="id y nombre de la busqueda"
              >
                <select
                  onChange={(e) => setChangeSearch(e.target.value)}
                  name="id_search"
                  id="asignar"
                >
                  <option value="0">Asignar búsqueda</option>
                  {searches.map((item, i) => (
                    <option key={i} value={item.search_id}>
                      {item.search_id + " - " + item.position_name}
                    </option>
                  ))}
                </select>
                <button id="editar" onClick={() => setModalShow(true)}>
                  EDITAR DATOS
                </button>
                {modalShow && (
                  <ModalInfoApplicant
                    data={data}
                    show={modalShow}
                    listUniversity={listUniversity}
                    setListUniversity={setListUniversity}
                    onHide={() => setModalShow(false)}
                  />
                )}
              </div>
              <div className="content-datos">
                <div className="content-linea">
                  <div className="input-error">
                    <label>Nombre</label>
                    <input
                      readOnly
                      name="name"
                      value={firstName}
                      type="text"
                      id="_primer_nombre"
                    />
                  </div>
                  <div className="input-error">
                    <label>Apellido</label>
                    <input
                      readOnly
                      name="name"
                      value={lastName}
                      type="text"
                      id="primer_apellido"
                    />
                  </div>
                </div>
                <div className="content-linea">
                  <div className="input-error">
                    <label>Titulo</label>
                    <input
                      readOnly
                      name="title"
                      value={title}
                      type="text"
                      id="titulo"
                    />
                  </div>
                  <div className="input-error">
                    <label>DNI</label>
                    <input readOnly name="dni" value={dni} type="text" id="dni" />
                  </div>
                </div>
                <div className="content-linea">
                  <div className="input-error">
                    <label>Universidad</label>
                    <input
                      readOnly
                      name="university"
                      value={university}
                      type="text"
                      id="universidad"
                    />
                  </div>
                  <div className="input-error">
                    <label>Email</label>
                    <input
                      readOnly
                      name="email"
                      value={email}
                      type="text"
                      id="mail"
                    />
                  </div>
                </div>
                <div className="content-linea">
                  <div className="input-error">
                    <label>Experiencia</label>
                    <input
                      readOnly
                      name="experience"
                      value={experience}
                      type="text"
                      id="experiencia"
                    />
                  </div>
                  <div className="input-error">
                    <label>Télefono</label>
                    <input
                      readOnly
                      name="phoneNumber"
                      value={phoneNumber}
                      type="text"
                      id="telefono"
                    />
                  </div>
                </div>
                <div className="content-linea">
                  <div className="input-error">
                    <label>Posición</label>
                    <input
                      readOnly
                      name="lastJobTitle"
                      value={lastJobTitle}
                      type="text"
                      id="ultima-pocision"
                    />
                  </div>

                  <div className="input-error">
                    <label>Empresa</label>
                    <input
                      readOnly
                      name="business"
                      value={business}
                      type="text"
                      id="empresa"
                    />
                  </div>

                  
                </div>
                <div className="content-linea">
                  <div className="input-error">
                    <label>Fecha de nacimiento</label>
                    <input
                      readOnly
                      name="birthday"
                      value={birthday}
                      type="text"
                      id="fecha-nac"
                    />
                  </div>
                  <div className="input-error">
                    <label>Personas a Cargo</label>
                    <input
                      readOnly
                      name="personInCharge"
                      value={personInCharge}
                      type="text"
                      id="a-cargo"
                    />
                  </div>
                </div>
                <div className="content-linea">
                  <div className="input-error">
                    <label>Educación</label>
                    <input
                      readOnly
                      name="education"
                      value={education}
                      type="text"
                      id="educacion"
                    />
                  </div>
                </div>
                <div className="content-linea">
                  <div className="opciones">
                    <div className="columna">
                      <div>
                        {linkCurriculum === "#" ? (
                          <span
                            onClick={() => open("No hay archivo adjunto")}
                            className="curriculum"
                          >
                            VER CV ADJUNTO <img src={curriculum} alt="" />
                          </span>
                        ) : (
                          <a
                            target="_blank"
                            rel="noopener noreferrer"
                            href={linkCurriculum}
                          >
                            <span className="curriculum">
                              VER CV ADJUNTO <img src={curriculum} alt="" />
                            </span>
                          </a>
                        )}
                      </div>
                      <div>
                        <input
                          accept=".pdf,.docx"
                          ref={file}
                          className="d-none"
                          name="selectedFile"
                          type="file"
                          onChange={(e) =>
                            checkvalue(e.target.name, e.target.files[0])
                          }
                        />
                        <span
                          onClick={(e) => handleClick()}
                          className="curriculum"
                        >
                          ADJUNTAR CV <img src={curriculum} alt="" />
                        </span>
                      </div>
                      <div className="mensajes">
                        <span>{nameFile}</span>
                        {/* <span className={selectedFileError ? 'span-error' : 'd-none'}>Agregue un CV</span> */}
                      </div>
                    </div>
                    <div className="columna">
                      <div
                        onClick={() => toggleApprove()}
                        className={`resaltar ${
                          outstanding == 1 ? "resaltado" : ""
                        }`}
                      >
                        RESALTAR CANDIDATO{" "}
                        <img
                          src={`${outstanding == 1 ? favoritoActive : favorito}`}
                          alt=""
                        />
                      </div>
                      <div
                        onClick={() => checkEmpty()}
                        className="guardar-cambios"
                        disabled={isLoading ? true : false}
                      >
                        REALIZAR CAMBIOS
                      </div>

                    </div>

                    <div className="columna button-group-notes">

                      {Constants.RRHH.includes(userData.role_id) && (
                        <div className="btn-group" role="group" aria-label="Basic example">
                          <button onClick={() => {
                            setModalNotes(true)
                            setTypeNotes('list')
                          }} type="button" className="btn btn-primary notes-btn d-flex align-items-center">VER NOTAS {countNotes > 0 && <span className="notes-count">{countNotes}</span>}</button>
                          <button onClick={() => {
                            setModalNotes(true)
                            setTypeNotes('add')
                          }} type="button" className="btn btn-primary notes-btn">+</button>
                        </div>
                      )}


                      {/* <span
                        onClick={() => setModalNotes(true)}
                        className=""
                      >
                        VER NOTAS &nbsp; +
                      </span> */}
                    </div>

                  </div>
                </div>
                <div className="opciones">
                  <table className="default table table-striped table_view_process">
                    <thead>
                      <tr>
                        <th scope="col">ID</th>
                        <th scope="col">Búsqueda</th>
                        <th scope="col">Estado</th>
                        <th scope="col">Perfil</th>
                        <th scope="col">Acciones</th>
                      </tr>
                    </thead>

                    <tbody>
                      
                      {allData.map((item, i) => {
                        
                        return [
                          <tr key={i}>
                            <td className={"align-middle " + (item.destacado === 1 ? 'candidato_destacado' : '')}>{item.id_search}</td>
                            <td className={"align-middle " + (item.destacado === 1 ? 'candidato_destacado' : '')}>{item.position_name}</td>
                            <td className={"align-middle " + (item.destacado === 1 ? 'candidato_destacado' : '')}>{item.detail_txt}</td>
                            <td className={"align-middle " + (item.destacado === 1 ? 'candidato_destacado' : '')}>{
                              (item.status_detail_id === 15) ? item.profile_detail : ''
                            }</td>
                            <td className="text-center align-middle">
                              {(item.status_detail_id !== 13) && (
                                <div
                                  id="button_proceso"
                                  onClick={() => handleProcessModal(item)}
                                  className="curriculum small"
                                >
                                  VER PROCESO
                                </div>
                              )}


                              {(item.status_id === 3 && Constants.RRHH.includes(userData.role_id)) && (
                                <>
                                &nbsp;
                                <div
                                  className="curriculum reactivar small"
                                  onClick={() => ReactivarProceso(item)}
                                >
                                  REACTIVAR PROCESO
                                </div>
                                </>
                              )}

                            </td>
                          </tr>,
                        ];
                      })}
                    </tbody>
                  </table>

                  {modalProcessShow && (
                    <ModalProcessApplicant
                      candidateId={id}
                      applicationId={applicationId}
                      profileId={profileId}
                      idSearch={idSearch}
                      data={data}
                      show={modalProcessShow}
                      onDataChange={onDataChange}
                      setModalProcessShow={setModalProcessShow}
                      onHide={() => setModalProcessShow(false)}
                    />
                  )}

                  {modalNotes && Constants.RRHH.includes(userData.role_id) && (
                    <ModalNotes
                      show={modalNotes}
                      onHide={() => setModalNotes(false)}
                      type={typeNotes}
                      id={id}
                    />
                  )}

                </div>
              </div>
            </div>
          </div>
        </div>
        )
      }

      {/* Modal de confirmación de suministración de cv o busqueda */}
      <div
        className="modal fade"
        id="asignar-cambio-otra-busqueda"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="basicModal"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-hidden="true"
              >
                &times;
              </button>
            </div>
            <div className="modal-body ">
              <span className="texto-general">
                ¿Estás seguro de realizar este cambio?
              </span>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-primary"
                id="ok_avanza"
                data-dismiss="modal"
                onClick={sendData}
              >
                Ok
              </button>
              <button
                type="button"
                className="btn btn-primary"
                id="cancel"
                data-dismiss="modal"
              >
                Cancelar
              </button>
            </div>
          </div>
        </div>
      </div>


      {/* Modal de reactivación de proceso */}
      <div
        className="modal fade"
        id="reactivar_proceso"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="basicModal"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-hidden="true"
              >
                &times;
              </button>
            </div>
            <div className="modal-body ">
              <div>
              <span className="texto-general text-left font-weight-normal m-3">
                Seleccione una opción:
              </span>
              <span className="texto-general text-left font-weight-normal m-3">
                <strong>Retomar el proceso</strong> desde el punto en el que se dejó, manteniendo los módulos ya completados.
              </span>
              <span className="texto-general text-left font-weight-normal m-3">
                <strong>Iniciar un nuevo proceso</strong> desde cero, con los módulos vacíos. La información completada hasta el momento no se elimina y se puede volver a consultar.
              </span>
              </div>
            </div>
            <div className="modal-footer">
              <div className="modal-action d-flex align-items-center justify-content-center">
                <button
                  type="button"
                  className="btn btn-primary btn-balanz-secondary"
                  id="ok_avanza"
                  onClick={() => reactivateSearch('?mode=new')}
                  data-dismiss="modal"
                >
                  Iniciar Nuevo Proceso
                </button>
                &nbsp;
                <button
                  type="button"
                  className="btn btn-info"
                  onClick={() => reactivateSearch()}
                  // data-dismiss="modal"
                >
                  Retomar Proceso
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>


    </>
  );
};

export default ApplicantInfo;
