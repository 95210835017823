import React, { useEffect, useState, useRef } from "react";
//import Footer from "../../includes/Footer/Footer";
import HeaderPublic from "../../includes/Header/HeaderPublic";
import "./newApplicantexternal.css";
// import check from '../../../images/menu/check.png'
// import favorito from '../../../images/menu/favorito.png'
import curriculum from "../../../images/menu/curriculum.png";
//import imagen_promo from "../../../images/menu/sumate_a_nuestro_equipo.png";
import { API } from "../../../http-common";
import moment from "moment";
import { Modal, Button } from "react-bootstrap";
import { FILE_SIZE_LIMIT } from "../../../constants";
//import { API_URL, PRODUCTION_URL, FILE_SIZE_LIMIT } from "../../../constants-stage";

let OptionsList = require("./OptionsList.json");

let whereFindOptions = [
  {value: 'Intranet', text: 'Intranet'},
  {value: 'Referido', text: 'Referido'},
  {value: 'Linkedin', text: 'Linkedin'},
  {value: 'Universidad', text: 'Universidad'},
  {value: 'Sitio corporativo Balanz', text: 'Sitio corporativo Balanz'},
  {value: 'Contactado por People', text: 'Contactado por People'}
]

const excludeoptions = ['Intranet', 'Contactado por People'];

const NewApplicantPublic = (req) => {
  // Ref
  const file = useRef("");
  const params = new URLSearchParams(req.location.search);
  const origen = params.get('origen')

  if (null === origen || !excludeoptions.includes(origen)) {
    whereFindOptions = whereFindOptions.filter(wherefind => !excludeoptions.includes(wherefind.value));
  }

  const origenFound = whereFindOptions.find(wf => wf.value === origen);

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  //Variables de formulario
  // const [name, setName] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [title, setTitle] = useState("");
  const [dni, setDni] = useState("");
  const [university, setUniversity] = useState("");
  const [email, setEmail] = useState("");
  const [experience, setExperience] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [lastJobTitle, setLastJobTitle] = useState("");
  const [business, setBusiness] = useState("");
  const [birthday, setBirthday] = useState("");
  const [personInCharge, setPersonInCharge] = useState("");
  const [education, setEducation] = useState("");
  const [whereFind, setWhereFind] = useState(null !== origen && typeof origenFound !== 'undefined' ? origenFound.value : '');
  const [friendRecomended, setFriendRecomended] = useState("");
  const [selectedFile, setSelectedFile] = useState("");
  const idSearch = req.match.params.id;
  const [isLoading, setIsLoading] = useState(true);
  const [search, setSearch] = useState([]);
  const [message, setMessage] = useState("Cargando...");
  const [noSearch, setNoSearch] = useState(null);
  const [isSentData, setIsSentData] = useState(false);
  const [sourceDetail, setSourceDetail] = useState("");
  const [departament, setDepartament] = useState("");
  const [departaments, setDepartaments] = useState([]);
  const [check_generic, setCheckGeneric] = useState(false);
  // objeto Form

  const formInitial = [
    {
      id_search: idSearch,
      name: `${firstName} ${lastName}`,
      firstName: firstName,
      lastName: lastName,
      title: title,
      dni: dni,
      university: university,
      email: email,
      experience: experience,
      phoneNumber: phoneNumber,
      lastJobTitle: lastJobTitle,
      business: business,
      birthday: birthday,
      personInCharge: personInCharge,
      education: education,
      whereFind: whereFind,
      departament: departament,
      friendRecomended: friendRecomended,
      source_detail: sourceDetail,
      selectedFile: selectedFile,
      highlighted: 0,
      outstanding: 0,
      status: 1,
      errorTest: 400,
    },
  ];

  const [formData, setFormData] = useState(formInitial);

  //Variables de validacion de error
  // const [nameError, setNameError] = useState(false)
  const [firstNameError, setFirstNameError] = useState(false);
  const [lastNameError, setLastNameError] = useState(false);
  const [titleError, setTitleError] = useState(false);
  const [dniError, setDniError] = useState(false);
  const [universityError, setUniversityError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [experienceError, setExperienceError] = useState(false);
  const [phoneNumberError, setPhoneNumberError] = useState(false);
  const [lastJobTitleError, setLastJobTitleError] = useState(false);
  const [businessError, setBusinessError] = useState(false);
  const [birthdayError, setBirthdayError] = useState(false);
  const [personInChargeError, setPersonInChargeError] = useState(false);
  const [educationError, setEducationError] = useState(false);
  const [whereFindError, setWhereFindError] = useState(false);
  const [selectedFileError, setSelectedFileError] = useState(false);
  const [sourceDetailError, setSourceDetailError] = useState(false);
  const [friendRecomendedError, setFriendRecomendedError] = useState(false);
  const [departamentError, setDepartamentError] = useState(false);
  const [listUniversity,setListUniversity] = useState([])
  //Variables de mensaje de error
  // const [nameWarning, setNameWarning] = useState('')
  const [firstNameWarning, setFirstNameWarning] = useState("");
  const [lastNameWarning, setLastNameWarning] = useState("");
  const [titleWarning, setTitleWarning] = useState("");
  const [dniWarning, setDniWarning] = useState("");
  const [universityWarning, setUniversityWarning] = useState("");
  const [emailWarning, setEmailWarning] = useState("");
  const [experienceWarning, setExperienceWarning] = useState("");
  const [phoneNumberWarning, setPhoneNumberWarning] = useState("");
  const [lastJobTitleWarning, setLastJobTitleWarning] = useState("");
  const [businessWarning, setBusinessWarning] = useState("");
  const [birthdayWarning, setBirthdayWarning] = useState("");
  const [personInChargeWarning, setPersonInChargeWarning] = useState("");
  const [educationWarning, setEducationWarning] = useState("");
  const [sourceDetailWarning, setSourceDetailWarning] = useState("");
  const [friendRecomendedWarining, setFriendRecomendedWarning] = useState("");
  const [departamentWarning, setDepartamentWarning] = useState("");

  const [whereFindWarning, setWhereFindWarning] = useState("");
  const [selectedFileWarning, setSelectedFileWarning] = useState("");
  const [nameFile, setNameFile] = useState("");

  const regExDate =
    /^(?:(?:(?:0?[1-9]|1\d|2[0-8])[/](?:0?[1-9]|1[0-2])|(?:29|30)[/](?:0?[13-9]|1[0-2])|31[/](?:0?[13578]|1[02]))[/](?:0{2,3}[1-9]|0{1,2}[1-9]\d|0?[1-9]\d{2}|[1-9]\d{3})|29[/]0?2[/](?:\d{1,2}(?:0[48]|[2468][048]|[13579][26])|(?:0?[48]|[13579][26]|[2468][048])00))$/;
  const regExName = /^[a-zA-Z]+[a-zA-Z]+$/;
  const regExLetters = /^[a-zA-ZÀ-ú ]+$/;
  const regExEmail = /\S+@\S+\.\S+/;

    const getListUniversity = () => {
    try {
      API.post("/candidates/university/public").then(function (response) {
        if (response.data.error === 0) {
          let list = response.data.data
          setListUniversity(list)
        }

      });
    }
    catch {
      setListUniversity([])
     throw new Error("Error al cargar la lista de universidades")
    }
  }

  function getSearche() {
    setIsLoading(true);
    API.get("/positions/public/" + idSearch).then(function (response) {
      setIsLoading(false);
      if (response.data.error === 0 && response.data.data.status === 3) {
        setSearch(response.data.data);
        setCheckGeneric(response.data.data.corporate === 1 && response.data.data.id_departament === 0)
        setMessage("Cargando...");
      } else {
        let msg = (
          <>
            <span>Lo sentimos.</span>
            <br /> Esta búsqueda ya no se encuentra activa.
          </>
        );
        setNoSearch(true);

        setMessage(msg);
      }
    });
  }

  const checkvalue = (nameInput, value) => {
    if ((value === "" || /^[0-9\b]+$/.test(value)) && nameInput === "dni") {
      setDni(value);
      updateFormData(nameInput, value);
    } else if (
      (value === "" || /^[0-9\b]+$/.test(value)) &&
      nameInput === "experience"
    ) {
      setExperience(value);
      updateFormData(nameInput, value);
    } else if (
      (value === "" || /^[0-9\b]+$/.test(value)) &&
      nameInput === "phoneNumber"
    ) {
      setPhoneNumber(value);
      updateFormData(nameInput, value);
    } else if (nameInput === "selectedFile") {
      setSelectedFile(value);
      setNameFile(value.name);
      updateFormData(nameInput, value);
    } else if (nameInput === "email") {
      setEmail(value);
      updateFormData(nameInput, value);
    } else if (nameInput === "birthday") {
      setBirthday(value);
      updateFormData(nameInput, value);
    } else if (
      (value === "" || /^[a-zA-ZÀ-ú ]+$/.test(value)) &&
      nameInput === "firstName"
    ) {
      setFirstName(value);
      updateFormData(nameInput, value);
    } else if (
      (value === "" || /^[a-zA-ZÀ-ú ]+$/.test(value)) &&
      nameInput === "lastName"
    ) {
      setLastName(value);
      updateFormData(nameInput, value);
    } else if (nameInput === "title") {
      setTitle(value);
      updateFormData(nameInput, value);
    } else if (nameInput === "university") {
      setUniversity(value);
      updateFormData(nameInput, value);
    } else if (nameInput === "lastJobTitle") {
      setLastJobTitle(value);
      updateFormData(nameInput, value);
    } else if (nameInput === "business") {
      setBusiness(value);
      updateFormData(nameInput, value);
    } else if (
      (value === "" || /^[0-9\b]+$/.test(value)) &&
      nameInput === "personInCharge"
    ) {
      setPersonInCharge(value);
      updateFormData(nameInput, value);
    } else if (nameInput === "education") {
      setEducation(value);
      updateFormData(nameInput, value);
    } else if (nameInput === "whereFind") {
      setWhereFind(value);
      updateFormData(nameInput, value);
    } else if (nameInput === "departament") {
      setDepartament(value);
      updateFormData(nameInput, value);
    } else if (
      (value === "" || /^[a-zA-ZÀ-ú ]+$/.test(value)) &&
      nameInput === "friendRecomended"
    ) {
      setFriendRecomended(value);
      updateFormData(nameInput, value);
    } else if (nameInput === "sourceDetail") {
      setSourceDetail(value);
      updateFormData(nameInput, value);
    }
  };

  const updateFormData = (nameInput, value) => {
    const newForm = formData.map((item, key) => {
      if (nameInput === "birthday") {
        const updatedItem = {
          ...item,
          [nameInput]: moment(value, "DD/MM/YYYY").format("YYYY-MM-DD"),
          key: key,
        };
        return updatedItem;
      } else {
        const updatedItem = { ...item, [nameInput]: value, key: key };
        return updatedItem;
      }
    });
    setFormData(newForm);
  };

  const checkEmpty = () => {
    let flag = 0;

    if (firstName.trim().length === 0) {
      flag++;
      setFirstNameError(true);
      setFirstNameWarning("El campo no puede estar vacío");
    } else if (!regExLetters.test(firstName)) {
      flag++;
      setFirstNameError(true);
      setFirstNameWarning("Ingrese solo letras");
    } else {
      setFirstNameError(false);
      setFirstNameWarning("");
    }

    if (lastName.trim().length === 0) {
      flag++;
      setLastNameError(true);
      setLastNameWarning("El campo no puede estar vacío");
    } else if (!regExLetters.test(lastName)) {
      flag++;
      setLastNameError(true);
      setLastNameWarning("Ingrese solo letras");
    } else {
      setLastNameError(false);
      setLastNameWarning("");
    }

    if (title.length === 0) {
      flag++;
      setTitleError(true);
      setTitleWarning("El campo no puede estar vacío");
    } else if (title.length > 0 && title.length <= 4) {
      flag++;
      setTitleError(true);
      setTitleWarning("Debe tener mas de 4 caracteres");
    } else {
      setTitleError(false);
      setTitleWarning("");
    }

    if (dni.length === 0) {
      flag++;
      setDniError(true);
      setDniWarning("El campo no puede estar vacío");
    } else if (dni.length > 0 && dni.length <= 5) {
      flag++;
      setDniError(true);
      setDniWarning("Debe tener mas de 5 caracteres");
    } else if (dni[0] === "0") {
      flag++;
      setDniError(true);
      setDniWarning("El primer caracter debe ser distinto de 0");
    } else {
      setDniError(false);
      setDniWarning("");
    }

    if (university.length === 0) {
      flag++;
      setUniversityError(true);
      setUniversityWarning("El campo no puede estar vacío");
    } else {
      setUniversityError(false);
      setUniversityWarning("");
    }

    if (
      whereFind === "Universidad"
    ) {
      if (sourceDetail.length === 0) {
        flag++;
        setSourceDetailError(true);
        setSourceDetailWarning("El campo no puede estar vacío");
      } else {
        setSourceDetailError(false);
        setSourceDetailWarning("");
      }
    }


    if (check_generic && departament.length === 0) {
      flag++;
      setDepartamentError(true);
      setDepartamentWarning("El campo no puede estar vacío");
    } else {
      setDepartamentError(false);
      setDepartamentWarning("");
    }


    if (whereFind === "Referido") {
      if (friendRecomended.trim().length === 0) {
        flag++;
        setFriendRecomendedError(true);
        setFriendRecomendedWarning("El campo no puede estar vacío");
      } else {
        setFriendRecomendedError(false);
        setFriendRecomendedWarning("");
      }
    }

    if (email.trim().length === 0) {
      flag++;
      setEmailError(true);
      setEmailWarning("El campo no puede estar vacío");
    } else if (!regExEmail.test(email)) {
      flag++;
      setEmailError(true);
      setEmailWarning("Ingrese un correo valido");
    } else {
      setEmailError(false);
      setEmailWarning("");
    }

    if (experience.trim().length === 0) {
      flag++;
      setExperienceError(true);
      setExperienceWarning("El campo no puede estar vacío");
    } else if (experience.length > 2) {
      flag++;
      setExperienceError(true);
      setExperienceWarning("Ingrese una cantidad permitida");
    } else {
      setExperienceError(false);
      setExperienceWarning("");
    }

    if (phoneNumber.trim().length === 0) {
      flag++;
      setPhoneNumberError(true);
      setPhoneNumberWarning("El campo no puede estar vacío");
    } else if (phoneNumber.length > 0 && phoneNumber.length <= 5) {
      flag++;
      setPhoneNumberError(true);
      setPhoneNumberWarning("Debe tener mas de 5 caracteres");
    } else {
      setPhoneNumberError(false);
      setPhoneNumberWarning("");
    }

    if (lastJobTitle.trim().length === 0) {
      flag++;
      setLastJobTitleError(true);
      setLastJobTitleWarning("El campo no puede estar vacío");
    } else {
      setLastJobTitleError(false);
      setLastJobTitleWarning("");
    }

    if (business.trim().length === 0) {
      flag++;
      setBusinessError(true);
      setBusinessWarning("El campo no puede estar vacío");
    } else {
      setBusinessError(false);
      setBusinessWarning("");
    }


    if (birthday.length === 0) {
      flag++;
      setBirthdayError(true);
      setBirthdayWarning("El campo no puede estar vacío");
    } else if (!regExDate.test(birthday)) {
      flag++;
      setBirthdayError(true);
      setBirthdayWarning("Ingrese el formato correcto de fecha");
    } else {
      setBirthdayError(false);
      setBirthdayWarning("");
    }

    if (personInCharge.length === 0) {
      flag++;
      setPersonInChargeError(true);
      setPersonInChargeWarning("El campo no puede estar vacío");
    } else {
      setPersonInChargeError(false);
      setPersonInChargeWarning("");
    }

    if (education.length === 0) {
      flag++;
      setEducationError(true);
      setEducationWarning("El campo no puede estar vacío");
    } else {
      setEducationError(false);
      setEducationWarning("");
    }

    if (!whereFind) {
      flag++;
      setWhereFindError(true);
      setWhereFindWarning("El campo no puede estar vacío");
    } else {
      setWhereFindError(false);
      setWhereFindWarning("");
    }

    if (selectedFile.size / 1048576 > FILE_SIZE_LIMIT) {
      flag++;
      setSelectedFileError(true);
      setSelectedFileWarning(
        `El archivo no puede pesar mas de ${FILE_SIZE_LIMIT}MB`
      );
    } else {
      setSelectedFileError(false);
      setSelectedFileWarning("");
    }
    return flag;
  };

  const sendData = async (e) => {
    formData[0].name = `${firstName} ${lastName}`;
    //if (whereFind !== "Amigo/Conocido") formData[0].friendRecomended = "";

    const form = new FormData();
    if (selectedFile !== "") {
      const ext = formData[0].selectedFile.name.substr(
        formData[0].selectedFile.name.lastIndexOf(".")
      );
      const fileName =
        `CANDIDATO_BUSQUEDA_${idSearch}_${dni}_${firstName}_${lastName}${ext}`
          .split(" ")
          .join("_")
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "");
      form.append("selectedFile", formData[0].selectedFile, fileName);

      formData[0].selectedFile = fileName;

      API.post("/applications/file", form, { timeout: 300000 })
          .then((response) => {
            setIsSentData(true);
            let msg = "";
            if (response.status === 200 && response.data.error === 0) {
              API.post("/applications/public/create", formData[0])
                  .then(function (response) {
                    if (response.status === 200) {
                      let msg = "";
                      setIsSentData(true);
                      msg = (
                          <>
                            <span>¡Gracias por completar tus datos!</span>
                            <br />
                            Muchas gracias por postularse. En caso que tu perfil se adecue
                            a nuestras búsquedas te vamos a contactar para coordinar una
                            entrevista.
                          </>
                      );
                      setMessage(msg);
                    }
                  })
                  .catch((error) => {
                    if (error.response) {
                      if (error.response.status === 500) {
                        let msg = "";
                        msg = (
                            <>
                              Hubo un problema al registrar tus datos, por favor intenta
                              nuevamente
                            </>
                        );
                        setMessage(msg);
                        handleShow();
                      } else if (error.response.status === 400) {
                        let msg = <>{error.response.data.message}</>;
                        setMessage(msg);
                        handleShow();
                      } else {
                        let msg = (
                            <>
                              Hubo un problema al conectar con el servidor, verifica tu
                              conexión a Internet e intenta nuevamente
                            </>
                        );
                        setMessage(msg);
                        handleShow();
                      }
                    } else {
                      let msg = (
                          <>
                            Hubo un problema al conectar con el servidor, verifica tu
                            conexión a Internet e intenta nuevamente
                          </>
                      );
                      setMessage(msg);
                      handleShow();
                    }
                  });
            } else {
              //todo
              msg = (
                  <>
                    <span>El formato de archivo no está permitido</span><br />(formatos permitidos jpg|jpeg|png|gif|txt|doc|docx|pdf)
                  </>
              );
            }
            setMessage(msg);
            handleShow();
          })
          .catch((error) => {
            let msg = (
                <>
                  <span>El formato de archivo no está permitido</span><br />(formatos permitidos jpg|jpeg|png|gif|txt|doc|docx|pdf)
                </>
            );
            setMessage(msg);
            handleShow();
          });
    }else{
      try {
        API.post("/applications/public/create", formData[0])
            .then(function (response) {
              if (response.status === 200) {
                let msg = "";
                setIsSentData(true);
                msg = (
                    <>
                      <span>¡Gracias por completar tus datos!</span>
                      <br />
                      Muchas gracias por postularse. En caso que tu perfil se adecue
                      a nuestras búsquedas te vamos a contactar para coordinar una
                      entrevista.
                    </>
                );
                setMessage(msg);
              }
            })
            .catch((error) => {
              if (error.response) {
                if (error.response.status === 500) {
                  let msg = "";
                  msg = (
                      <>
                        Hubo un problema al registrar tus datos, por favor intenta
                        nuevamente
                      </>
                  );
                  setMessage(msg);
                  handleShow();
                } else if (error.response.status === 400) {
                  let msg = <>{error.response.data.message}</>;
                  setMessage(msg);
                  handleShow();
                } else {
                  let msg = (
                      <>
                        Hubo un problema al conectar con el servidor, verifica tu
                        conexión a Internet e intenta nuevamente
                      </>
                  );
                  setMessage(msg);
                  handleShow();
                }
              } else {
                let msg = (
                    <>
                      Hubo un problema al conectar con el servidor, verifica tu
                      conexión a Internet e intenta nuevamente
                    </>
                );
                setMessage(msg);
                handleShow();
              }
            });
      } catch (error) {
        let msg = "";
        msg = (
            <>
              Hubo un problema al conectar con el servidor, verifica tu conexión a
              Internet e intenta nuevamente desde catch
            </>
        );
        setMessage(msg);
        handleShow();
      }
    }


  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (checkEmpty() === 0) {
      sendData();
    }
  };
  const handleClick = (e) => {
    file.current.click();
  };

  const getDepartaments = () => {
    API.get('/positions/departaments/public')
    .then(function (response) {
        if (response.data.error === 0) {
            setDepartaments(response.data.data)
        } else {
            //todo
        }
    })
  }

  useEffect(() => {
    getSearche();
    getDepartaments();
    getListUniversity();
  }, []);

  return (
    <>
      {/* <MetaTags>
                <title>{`Balanz - Buscamos ${search.position_name}`}</title>
                <meta name="description" content="Some description." />
                <meta property="og:url" content={`${PRODUCTION_URL}/${search.id}`} />
                <meta property="og:type" content="website" />
                <meta property="og:title" content={`Buscamos ${search.position_name}`} />
                <meta property="og:description" content={search.description_task} />
                <meta property="og:image" content={imagen_promo} />
            </MetaTags> */}
      <HeaderPublic />

      {isLoading || noSearch || isSentData ? (
        <div className="content-formulario aplicant_external">
          <div className="formulario newapplicant">
            <span
              className="Complete"
              style={{ margin: "25% auto", textAlign: "center" }}
            >
              {message}
            </span>
          </div>
        </div>
      ) : (
        <div className="content-formulario aplicant_external">
          <div className="formulario newapplicant">
            <span className="Complete">
              Complete el siguiente formulario<br></br>
              <span>para participar de la búsqueda laboral</span>
            </span>
            <br />
            <span className="ruta">
              POSICIÓN: &nbsp;<b className="cargo">{search.position_name}</b>
            </span>

            <form method="POST" onSubmit={handleSubmit}>
              {search.description_task && (
                <div className="content-description">
                  <span className="title">DESCRIPCIÓN</span>
                  <span className="description">
                    {search.description_task.split("\n").map((element, key) => {
                      return (
                        <span key={key}>
                          {element}
                          <br />{" "}
                        </span>
                      );
                    })}
                  </span>
                </div>
              )}
              <div className="content-datos">
                <div className="content-linea">
                  <div className="input-error">
                    <span>Nombre</span>
                    <input
                      name="firstName"
                      value={firstName}
                      type="text"
                      id="primer_nombre"
                      onChange={(e) =>
                        checkvalue(e.target.name, e.target.value)
                      }
                      placeholder="Nombre"
                    />
                    <div
                      className={`alert alert-danger mt-3 ${
                        firstNameError ? "" : "d-none"
                      }`}
                    >
                      {firstNameWarning}
                    </div>
                  </div>
                  <div className="input-error">
                    <span>Apellido</span>
                    <input
                      name="lastName"
                      value={lastName}
                      type="text"
                      id="primer_apellido"
                      onChange={(e) =>
                        checkvalue(e.target.name, e.target.value)
                      }
                      placeholder="Apellido"
                    />
                    <div
                      className={`alert alert-danger mt-3 ${
                        lastNameError ? "" : "d-none"
                      }`}
                    >
                      {lastNameWarning}
                    </div>
                  </div>
                </div>
                <div className="content-linea">
                  <div className="input-error">
                    <span>Educación</span>
                    <select
                      name="education"
                      value={education}
                      onChange={(e) =>
                        checkvalue(e.target.name, e.target.value)
                      }
                    >
                      {OptionsList.Grado.map((element, key) => {
                        return (
                          <option value={key === 0 ? "" : element} key={key}>
                            {element}
                          </option>
                        );
                      })}
                    </select>

                    <div
                      className={`alert alert-danger mt-3 ${
                        educationError ? "" : "d-none"
                      }`}
                    >
                      {educationWarning}
                    </div>
                  </div>
                  <div className="input-error">
                    <span>DNI</span>
                    <input
                      name="dni"
                      value={dni}
                      type="text"
                      id="dni"
                      onChange={(e) =>
                        checkvalue(e.target.name, e.target.value)
                      }
                      placeholder="DNI"
                    />
                    <div
                      className={`alert alert-danger mt-3 ${
                        dniError ? "" : "d-none"
                      }`}
                    >
                      {dniWarning}
                    </div>
                  </div>
                </div>
                <div className="content-linea">
                  <div className="input-error">
                    <span>Titulo</span>
                    <select
                      name="title"
                      value={title}
                      onChange={(e) =>
                        checkvalue(e.target.name, e.target.value)
                      }
                    >
                      {OptionsList.Carreras.map((element, key) => {
                        return (
                          <option value={key === 0 ? "" : element} key={key}>
                            {element}
                          </option>
                        );
                      })}
                    </select>

                    <div
                      className={`alert alert-danger mt-3 ${
                        titleError ? "" : "d-none"
                      }`}
                    >
                      {titleWarning}
                    </div>
                  </div>

                  <div className="input-error">
                    <span>E-mail</span>
                    <input
                      name="email"
                      value={email}
                      type="text"
                      id="mail"
                      onChange={(e) =>
                        checkvalue(e.target.name, e.target.value)
                      }
                      placeholder="E-mail"
                    />
                    <div
                      className={`alert alert-danger mt-3 ${
                        emailError ? "" : "d-none"
                      }`}
                    >
                      {emailWarning}
                    </div>
                  </div>
                </div>

                <div className="content-linea">
                  <div className="input-error">
                    <span>Universidad</span>
                    <select
                        style={{ marginTop: "5px" }}
                        name="university"
                        value={university}
                        onChange={(e) => checkvalue(e.target.name, e.target.value )}
                      > <>
                        <option value="">Universidades</option>
                        {
                          listUniversity.map((element, key) => {
   
                            return (
                              <option value={element.value} key={element.value}>
                                {element.name}
                              </option>
                             
                            )
                            
                          })
                        }
                        </>
                      </select>

                    <div
                      className={`alert alert-danger mt-3 ${
                        universityError ? "" : "d-none"
                      }`}
                    >
                      {universityWarning}
                    </div>
                  </div>

                  <div className="input-error">
                    <span>Teléfono</span>
                    <input
                      name="phoneNumber"
                      value={phoneNumber}
                      type="text"
                      id="telefono"
                      onChange={(e) =>
                        checkvalue(e.target.name, e.target.value)
                      }
                      placeholder="Teléfono"
                    />
                    <div
                      className={`alert alert-danger mt-3 ${
                        phoneNumberError ? "" : "d-none"
                      }`}
                    >
                      {phoneNumberWarning}
                    </div>
                  </div>
                </div>

                <div className="content-linea">
                  <div className="input-error">
                    <span>Nombre de la última posición</span>
                    <input
                      name="lastJobTitle"
                      value={lastJobTitle}
                      type="text"
                      id="ultima-pocision"
                      onChange={(e) =>
                        checkvalue(e.target.name, e.target.value)
                      }
                      placeholder="Nombre de última posicion"
                    />
                    <div
                      className={`alert alert-danger mt-3 ${
                        lastJobTitleError ? "" : "d-none"
                      }`}
                    >
                      {lastJobTitleWarning}
                    </div>
                  </div>
                  <div className="input-error">
                    <span>Empresa</span>
                    <input
                      name="business"
                      value={business}
                      type="text"
                      id="empresa"
                      onChange={(e) =>
                        checkvalue(e.target.name, e.target.value)
                      }
                      placeholder="Nombre de la empresa"
                    />
                    <div
                      className={`alert alert-danger mt-3 ${
                        businessError ? "" : "d-none"
                      }`}
                    >
                      {businessWarning}
                    </div>
                  </div>
                  
                </div>

                <div className="content-linea">
                <div className="input-error">
                    <span>Fecha de nacimiento (dd/mm/aaaa)</span>
                    <input
                      name="birthday"
                      value={birthday}
                      type="text"
                      id="fecha-nac"
                      onChange={(e) =>
                        checkvalue(e.target.name, e.target.value)
                      }
                      placeholder="Fecha de nacimiento (dd/mm/aaaa)"
                    />
                    <div
                      className={`alert alert-danger mt-3 ${
                        birthdayError ? "" : "d-none"
                      }`}
                    >
                      {birthdayWarning}
                    </div>
                  </div>
                  <div className="input-error">
                    <span>Años de experiencia</span>
                    <input
                      name="experience"
                      value={experience}
                      type="text"
                      id="experiencia"
                      onChange={(e) =>
                        checkvalue(e.target.name, e.target.value)
                      }
                      placeholder="Años de experiencia"
                    />
                    <div
                      className={`alert alert-danger mt-3 ${
                        experienceError ? "" : "d-none"
                      }`}
                    >
                      {experienceWarning}
                    </div>
                  </div>

                  
                </div>

                <div className="content-linea">
                  <div className="input-error">
                    <span>Gente a cargo</span>
                    <input
                      name="personInCharge"
                      value={personInCharge}
                      type="text"
                      id="a-cargo"
                      onChange={(e) =>
                        checkvalue(e.target.name, e.target.value)
                      }
                      placeholder="Gente a cargo"
                    />
                    <div
                      className={`alert alert-danger mt-3 ${
                        personInChargeError ? "" : "d-none"
                      }`}
                    >
                      {personInChargeWarning}
                    </div>
                  </div>
                  <div className="input-error">
                    <span>¿Cómo encontró esta postulación?</span>
                    <select
                      name="whereFind"
                      value={whereFind}
                      disabled={null !== origen && typeof origenFound !== 'undefined'}
                      onChange={(e) =>
                        checkvalue(e.target.name, e.target.value)
                      }
                    >
                      <option value="">Seleccionar</option>
                      {whereFindOptions.map(wf => <option value={wf.value}>{wf.text}</option> )}
                    </select>
                    <div
                      className={`alert alert-danger mt-3 ${
                        whereFindError ? "" : "d-none"
                      }`}
                    >
                      {whereFindWarning}
                    </div>
                  </div>

                  
                </div>
                <div className="content-linea">
                {check_generic && (
                    <div className="input-error">
                      <span>¿Cuál es el departamento de Balanz al que te interesa postularte?</span>
                      <select
                        name="departament"
                        value={departament}
                        onChange={(e) =>
                          checkvalue(e.target.name, e.target.value)
                        }
                      >
                        <option value="">Seleccionar</option>
                        {departaments.map((item, i) => <option key={i} value={item.id_departament}>{item.departament}</option>)}
                      </select>
                      <div
                        className={`alert alert-danger mt-3 ${
                          departamentError ? "" : "d-none"
                        }`}
                      >
                        {departamentWarning}
                      </div>
                    </div>
                  )}

                  {whereFind === "Referido" && (
                    <div className="input-error">
                      <span>Complete con el nombre del empleado de Balanz que lo refirió</span>
                      <input
                        name="friendRecomended"
                        value={friendRecomended}
                        type="text"
                        id="a-cargo"
                        onChange={(e) =>
                          checkvalue(e.target.name, e.target.value)
                        }
                        placeholder="Nombre de tu referencia"
                      />
                      {/* <div className={`alert alert-danger mt-3 ${personInChargeError ? "" : "d-none"}`}>{personInChargeWarning}</div> */}

                      <div
                        className={`alert alert-danger mt-3 ${
                          friendRecomendedError ? "" : "d-none"
                        }`}
                      >
                        {friendRecomendedWarining}
                      </div>
                    </div>
                  )}

                  {/* {whereFind === "Feria de empleos Universitaria" && (
                    <div className="input-error">
                      <span>Fuente</span>
                      <select
                        name="sourceDetail"
                        value={sourceDetail}
                        onChange={(e) =>
                          checkvalue(e.target.name, e.target.value)
                        }
                      >
                        {OptionsList.Universidades.map((element, key) => {
                          return (
                            <option value={key === 0 ? "" : element} key={key}>
                              {element}
                            </option>
                          );
                        })}
                      </select>
                      <div
                        className={`alert alert-danger mt-3 ${
                          sourceDetailError ? "" : "d-none"
                        }`}
                      >
                        {sourceDetailWarning}
                      </div>
                    </div>
                  )} */}

                  {whereFind === "Universidad" && (
                    <div className="input-error">
                      <span>Fuente</span>
                      <select
                        name="sourceDetail"
                        value={sourceDetail}
                        onChange={(e) =>
                          checkvalue(e.target.name, e.target.value)
                        }
                      >
                        {OptionsList.Universidades.map((element, key) => {
                          return (
                            <option value={key === 0 ? "" : element} key={key}>
                              {element}
                            </option>
                          );
                        })}
                      </select>
                      <div
                        className={`alert alert-danger mt-3 ${
                          sourceDetailError ? "" : "d-none"
                        }`}
                      >
                        {sourceDetailWarning}
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className="botones">
              <span>* Todos los campos son obligatorios</span>
              <span>Búsqueda laboral equitativa. El empleador sólo podrá solicitarle la información {<><br/> </>}
              estrictamente necesaria para el desempeño en el trabajo que se ofrece. Ley N°6471.</span>
                <input
                  accept=".pdf,.docx,.doc"
                  className="d-none"
                  ref={file}
                  name="selectedFile"
                  type="file"
                  onChange={(e) => checkvalue(e.target.name, e.target.files[0])}
                />
                <div onClick={(e) => handleClick()} className="curriculum">
                  <img src={curriculum} alt="CV" />
                  ADJUNTAR CV EN WORD / PDF
                </div>
                <span>{nameFile}</span>
                <div
                  className={`alert alert-danger mt-3 ${
                    selectedFileError === 1 ? "" : "d-none"
                  }`}
                >
                  {selectedFileWarning}
                </div>
                <button type="submit" className="crear">
                  POSTULAR
                </button>
              </div>
            </form>
          </div>
        </div>
      )}

      <Modal
        show={show}
        backdrop={"static"}
        className="modal_candidato"
        onHide={handleClose}
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>{message}</Modal.Body>
        <Modal.Footer>
          <Button onClick={handleClose}>Cerrar</Button>
        </Modal.Footer>
      </Modal>
    </>
  );

};

export default NewApplicantPublic;
