import Footer from "../../includes/Footer/Footer";
import Header from "../../includes/Header/Header";
import Menu from "../../includes/Menu/Menu";
import "./newApplicant.css";
import favorito from "../../../images/menu/favorito.png";
import favoritoActive from "../../../images/menu/resaltado.png";
import curriculum from "../../../images/menu/curriculum.png";
import React, { useEffect, useState, useRef } from "react";
import { API } from "../../../http-common";
import { Link, useHistory } from "react-router-dom";
import { Modal, Button } from "react-bootstrap";
import moment from "moment";
import { FILE_SIZE_LIMIT } from "../../../constants";
import { useSnackbar } from "react-simple-snackbar";
let OptionsList = require("./OptionsList.json");

let whereFindOptions = [
  {value: 'Intranet', text: 'Intranet'},
  {value: 'Referido', text: 'Referido'},
  {value: 'Linkedin', text: 'Linkedin'},
  {value: 'Universidad', text: 'Universidad'},
  {value: 'Sitio corporativo Balanz', text: 'Sitio corporativo Balanz'},
  {value: 'Contactado por People', text: 'Contactado por People'}
]


const NewApplicant = () => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const history = useHistory();

  const file = useRef("");

  const options = {
    style: {
      backgroundColor: "#c60000",
      color: "white",
      borderRadius: "10px",
      fontSize: "18px",
      textAlign: "center",
    },
    closeStyle: {
      fontSize: "16px",
    },
  };

  const [open] = useSnackbar(options);

  //Variables de formulario
  const [name, setName] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [title, setTitle] = useState("");
  const [dni, setDni] = useState("");
  const [university, setUniversity] = useState("");
  const [universityLabel, setUniversityLabel] = useState("");
  const [email, setEmail] = useState("");
  const [experience, setExperience] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [lastJobTitle, setLastJobTitle] = useState("");
  const [business, setBusiness] = useState("");
  const [birthday, setBirthday] = useState("");
  const [personInCharge, setPersonInCharge] = useState("");
  const [education, setEducation] = useState("");
  const [whereFind, setWhereFind] = useState('');
  const [whereFindError, setWhereFindError] = useState(false);
  const [whereFindWarning, setWhereFindWarning] = useState("");
  const [friendRecomended, setFriendRecomended] = useState("");
  const [selectedFile, setSelectedFile] = useState("");
  const [idSearch, setIdSearch] = useState("");
  const [highlighted, setHighlighted] = useState(0);
  const [outstanding, setOutstanding] = useState(0);
  const [httpStatus, setHttpStatus] = useState("");
  const [isSentData, setIsSentData] = useState(false);
  const [message, setMessage] = useState("Cargando...");
  const [sourceDetail, setSourceDetail] = useState("");
  const [sourceValue, setSourceValue] = useState("");
  const [nameFile, setNameFile] = useState("");
  // objeto Form

  const formInitial = [
    {
      id_search: idSearch,
      name: `${firstName} ${lastName}`,
      firstName: firstName,
      lastName: lastName,
      title: title,
      dni: dni,
      university: university,
      universityLabel:universityLabel,
      email: email,
      experience: experience,
      phoneNumber: phoneNumber,
      lastJobTitle: lastJobTitle,
      business: business,
      birthday: birthday,
      personInCharge: personInCharge,
      education: education,
      whereFind: whereFind,
      friendRecomended: friendRecomended,
      source_detail: sourceDetail,
      selectedFile: selectedFile,
      highlighted: highlighted,
      outstanding: outstanding,
      status: 1,
    },
  ];

  const [formData, setFormData] = useState(formInitial);

  //Variables de validacion de error
  const [nameError, setNameError] = useState(false);
  const [firstNameError, setFirstNameError] = useState(false);
  const [lastNameError, setLastNameError] = useState(false);
  const [titleError, setTitleError] = useState(false);
  const [dniError, setDniError] = useState(false);
  const [universityError, setUniversityError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [experienceError, setExperienceError] = useState(false);
  const [phoneNumberError, setPhoneNumberError] = useState(false);
  const [lastJobTitleError, setLastJobTitleError] = useState(false);
  const [businessError, setBusinessError] = useState(false);
  const [birthdayError, setBirthdayError] = useState(false);
  const [personInChargeError, setPersonInChargeError] = useState(false);
  const [educationError, setEducationError] = useState(false);
  const [idSearchError, setIdSearchError] = useState("");
  const [selectedFileError, setSelectedFileError] = useState(false);

  //Variables de mensaje de error
  const [nameWarning, setNameWarning] = useState("");
  const [firstNameWarning, setFirstNameWarning] = useState("");
  const [lastNameWarning, setLastNameWarning] = useState("");
  const [titleWarning, setTitleWarning] = useState("");
  const [dniWarning, setDniWarning] = useState("");
  const [universityWarning, setUniversityWarning] = useState("");
  const [emailWarning, setEmailWarning] = useState("");
  const [experienceWarning, setExperienceWarning] = useState("");
  const [phoneNumberWarning, setPhoneNumberWarning] = useState("");
  const [lastJobTitleWarning, setLastJobTitleWarning] = useState("");
  const [businessWarning, setBusinessWarning] = useState("");
  const [birthdayWarning, setBirthdayWarning] = useState("");
  const [personInChargeWarning, setPersonInChargeWarning] = useState("");
  const [educationWarning, setEducationWarning] = useState("");
  const [idSearchWarning, setIdSearchWarning] = useState("");
  const [selectedFileWarning, setSelectedFileWarning] = useState("");
  const [listUniversity,setListUniversity] = useState([])
  const [sourceDetailError, setSourceDetailError] = useState(false);
  const [sourceDetailWarning, setSourceDetailWarning] = useState("");
  const [check_generic, setCheckGeneric] = useState(false);
  const [friendRecomendedError, setFriendRecomendedError] = useState(false);
  const [friendRecomendedWarining, setFriendRecomendedWarning] = useState("");
  const regExDate =
    /^(?:(?:(?:0?[1-9]|1\d|2[0-8])[/](?:0?[1-9]|1[0-2])|(?:29|30)[/](?:0?[13-9]|1[0-2])|31[/](?:0?[13578]|1[02]))[/](?:0{2,3}[1-9]|0{1,2}[1-9]\d|0?[1-9]\d{2}|[1-9]\d{3})|29[/]0?2[/](?:\d{1,2}(?:0[48]|[2468][048]|[13579][26])|(?:0?[48]|[13579][26]|[2468][048])00))$/;
  const regExName = /^[a-zA-Z]+[a-zA-Z]+$/;
  const regExLetters = /^[a-zA-ZÀ-ú ]+$/;
  const regExEmail = /\S+@\S+\.\S+/;

  const [searches, setSearches] = useState([]);

  function getSearches() {
    API.get("/positions/opened/0").then(function (response) {
      if (response.data.error === 0) {
        setSearches(response.data.data);
      } else {
        //todo
      }
    });
  }
  const getListUniversity = () => {
    try {
      API.post("/candidates/university").then(function (response) {
        if (response.data.error === 0) {
          let list = response.data.data
          setListUniversity(list)
        }

      });
    }
    catch {
      setListUniversity([])
     throw new Error("Error al cargar la lista de universidades")
    }
  }
  const checkvalue = (nameInput, value, label) => {
    if ((value === "" || /^[0-9\b]+$/.test(value)) && nameInput === "dni") {
      setDni(value);
      updateFormData(nameInput, value);
    } else if (
      (value === "" || /^[0-9\b]+$/.test(value)) &&
      nameInput === "experience"
    ) {
      setExperience(value);
      updateFormData(nameInput, value);
    } else if (
      (value === "" || /^[0-9\b]+$/.test(value)) &&
      nameInput === "phoneNumber"
    ) {
      setPhoneNumber(value);
      updateFormData(nameInput, value);
    } else if (nameInput === "selectedFile") {
      setSelectedFile(value);
      setNameFile(value.name);
      updateFormData(nameInput, value);
    } else if (nameInput === "email") {
      setEmail(value);
      updateFormData(nameInput, value);
    } else if (nameInput === "birthday") {
      setBirthday(value);
      updateFormData(nameInput, value);
    } else if (
      (value === "" || /^[a-zA-ZÀ-ú ]+$/.test(value)) &&
      nameInput === "firstName"
    ) {
      setFirstName(value);
      updateFormData(nameInput, value);
    } else if (
      (value === "" || /^[a-zA-ZÀ-ú ]+$/.test(value)) &&
      nameInput === "lastName"
    ) {
      setLastName(value);
      updateFormData(nameInput, value);
    } else if (nameInput === "title") {
      setTitle(value);
      updateFormData(nameInput, value);
    } else if (nameInput === "university") {
      setUniversity(value);
      updateFormData(nameInput, value);
      setUniversityLabel(label);
    }
    
    else if (nameInput === "lastJobTitle") {
      setLastJobTitle(value);
      updateFormData(nameInput, value);
    } 
    else if (nameInput === "business") {
      setBusiness(value);
      updateFormData(nameInput, value);
    }
    else if (
      (value === "" || /^[0-9\b]+$/.test(value)) &&
      nameInput === "personInCharge"
    ) {
      setPersonInCharge(value);
      updateFormData(nameInput, value);
    } else if (nameInput === "education") {
      setEducation(value);
      updateFormData(nameInput, value);
    } else if (nameInput === "whereFind") {
      setWhereFind(value);
      updateFormData(nameInput, value);
    } else if (
      (value === "" || /^[a-zA-ZÀ-ú ]+$/.test(value)) &&
      nameInput === "friendRecomended"
    ) {
      setFriendRecomended(value);
      updateFormData(nameInput, value);
    } else if (nameInput === "id_search") {
      setIdSearch(value);
      updateFormData(nameInput, value);
    } else if (nameInput === "highlighted") {
      if (parseInt(value) === 0) {
        setHighlighted(1);
        updateFormData(nameInput, 1);
      } else {
        setHighlighted(0);
        updateFormData(nameInput, 0);
      }

    }
    else if (nameInput === "sourceDetail") { 
      setSourceValue(value)
      setSourceDetail(label);
      updateFormData(nameInput, label);
    }
  };

  const form = new FormData();

  const updateFormData = (nameInput, value) => {
    const newForm = formData.map((item) => {
      if (nameInput === "birthday") {
        const updatedItem = {
          ...item,
          [nameInput]: moment(value, "DD/MM/YYYY").format("YYYY-MM-DD"),
        };
        return updatedItem;
      } else {
        const updatedItem = { ...item, [nameInput]: value };
        return updatedItem;
      }
    });
    setFormData(newForm);
  };

  const checkEmpty = () => {
    let flag = 0;
    if (firstName.trim().length === 0) {
      flag++;
      setFirstNameError(true);
      setFirstNameWarning("El campo no puede estar vacío");
    } else if (!regExLetters.test(firstName)) {
      flag++;
      setFirstNameError(true);
      setFirstNameWarning("Ingrese solo letras");
    } else {
      setFirstNameError(false);
      setFirstNameWarning("");
    }

    if (lastName.trim().length === 0) {
      flag++;
      setLastNameError(true);
      setLastNameWarning("El campo no puede estar vacío");
    } else if (!regExLetters.test(lastName)) {
      flag++;
      setLastNameError(true);
      setLastNameWarning("Ingrese solo letras");
    } else {
      setLastNameError(false);
      setLastNameWarning("");
    }

    if (title.length === 0) {
      flag++;
      setTitleError(true);
      setTitleWarning("El campo no puede estar vacío");
    } else if (title.length > 0 && title.length <= 4) {
      flag++;
      setTitleError(true);
      setTitleWarning("Debe tener mas de 4 caracteres");
    } else {
      setTitleError(false);
      setTitleWarning("");
    }

    if (dni.length === 0) {
      flag++;
      setDniError(true);
      setDniWarning("El campo no puede estar vacío");
    } else if (dni.length < 8) {
      flag++;
      setDniError(true);
      setDniWarning("Ingrese un DNI valido");
    } else if (dni[0] === "0") {
      setDniError(true);
      setDniWarning("El primer caracter de este campo no puede ser 0");
    } else if (typeof dni == !"number") {
      setDniError(true);
      setDniWarning(
        "El campo no puede contener caracteres distintos a numeros"
      );
    } else {
      setDniError(false);
      setDniWarning("");
    }

    if (university.length === 0) {
      flag++;
      setUniversityError(true);
      setUniversityWarning("El campo no puede estar vacío");

    } else {
      setUniversityError(false);
      setUniversityWarning("");
    }
    if (
      whereFind === "Universidad"
    ) {
      if (sourceDetail.length === 0) { 
        flag++;
        setSourceDetailError(true);
        setSourceDetailWarning("El campo no puede estar vacío");
      } else {
        setSourceDetailError(false);
        setSourceDetailWarning("");
      }
    }


    if (whereFind === "Referido") {
      if (friendRecomended.trim().length === 0) {
        flag++;
        setFriendRecomendedError(true);
        setFriendRecomendedWarning("El campo no puede estar vacío");
      } else {
        setFriendRecomendedError(false);
        setFriendRecomendedWarning("");
      }
    }
    if(whereFind === "Referido") {
      if(friendRecomended.trim().length > 0 && friendRecomended.length < 5){
        flag++;
        setFriendRecomendedError(true);
        setFriendRecomendedWarning("El campo debe tener mas de 5 caracteres");
      }
      else{
        setFriendRecomendedError(false);
        setFriendRecomendedWarning("");
      }
    }

    if (email.trim().length === 0) {
      flag++;
      setEmailError(true);
      setEmailWarning("El campo no puede estar vacío");
    } else if (!regExEmail.test(email)) {
      flag++;
      setEmailError(true);
      setEmailWarning("Ingrese un correo valido");
    } else {
      setEmailError(false);
      setEmailWarning("");
    }

    if (experience.length === 0) {
      flag++;
      setExperienceError(true);
      setExperienceWarning("El campo no puede estar vacío");
    } else if (experience.length > 2) {
      flag++;
      setExperienceError(true);
      setExperienceWarning("Ingrese una cantidad permitida");
    } else {
      setExperienceError(false);
      setExperienceWarning("");
    }

    if (phoneNumber.length === 0) {
      flag++;
      setPhoneNumberError(true);
      setPhoneNumberWarning("El campo no puede estar vacío");
    } else if (phoneNumber.length <= 5) {
      flag++;
      setPhoneNumberError(true);
      setPhoneNumberWarning("Ingrese un numero valido");
    } else {
      setPhoneNumberError(false);
      setPhoneNumberWarning("");
    }

    if (lastJobTitle.length === 0) {
      flag++;
      setLastJobTitleError(true);
      setLastJobTitleWarning("El campo no puede estar vacío");
    } else if (lastJobTitle.length > 0 && lastJobTitle.length < 3) {
      flag++;
      setLastJobTitleError(true);
      setLastJobTitleWarning("Ingrese más caracteres");
    } else {
      setLastJobTitleError(false);
      setLastJobTitleWarning("");
    }

    if (business.length === 0) {
      flag++;
      setBusinessError(true);
      setBusinessWarning("El campo no puede estar vacío");
    } else {
      setBusinessError(false);
      setBusinessWarning("");
    }

    if (birthday.length === 0) {
      flag++;
      setBirthdayError(true);
      setBirthdayWarning("El campo no puede estar vacío");
    } else if (!regExDate.test(birthday)) {
      flag++;
      setBirthdayError(true);
      setBirthdayWarning("Ingrese el formato correcto de fecha");
    } else {
      setBirthdayError(false);
      setBirthdayWarning("");
    }

    if (personInCharge.length === 0) {
      flag++;
      setPersonInChargeError(true);
      setPersonInChargeWarning("El campo no puede estar vacío");
    } else {
      setPersonInChargeError(false);
      setPersonInChargeWarning("");
    }
    if (!whereFind) {
      flag++;
      setWhereFindError(true);
      setWhereFindWarning("El campo no puede estar vacío");
    } else {
      setWhereFindError(false);
      setWhereFindWarning("");
    }


    if (education.length === 0) {
      flag++;
      setEducationError(true);
      setEducationWarning("El campo no puede estar vacío");
    } else {
      setEducationError(false);
      setEducationWarning("");
    }

    if (idSearch === "") {
      flag++;
      setIdSearchError(true);
      setIdSearchWarning("Seleccione una búsqueda");
    } else {
      setIdSearchError(false);
      setIdSearchWarning("");
    }

    if (selectedFile.size / 1048576 > FILE_SIZE_LIMIT) {
      flag++;
      setSelectedFileError(true);
      setSelectedFileWarning(
        `El archivo no puede pesar mas de ${FILE_SIZE_LIMIT}MB`
      );
    } else {
      setSelectedFileError(false);
      setSelectedFileWarning("");
    }
    if (flag === 0) {
      setIsSentData(true);
    }
    return flag;
  };

  const sendData = async (e) => {
    formData[0].name = `${firstName} ${lastName}`;
    formData[0].universityLabel = universityLabel
    if (selectedFile !== "") {
      const ext = formData[0].selectedFile.name.substr(
        formData[0].selectedFile.name.lastIndexOf(".")
      );
      const fileName =
        `CANDIDATO_BUSQUEDA_${idSearch}_${dni}_${firstName}_${lastName}${ext}`
          .split(" ")
          .join("_")
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "");
      form.append("selectedFile", formData[0].selectedFile, fileName);

      formData[0].selectedFile = fileName;
      formData[0].whereFind = whereFind;

      console.log('formData being sent', formData[0]);
      API.post("/applications/file", form, { timeout: 300000 })
          .then((response) => {
            let msg = "";
            if (response.status === 200 && response.data.error === 0) {
              msg = (
                  <>
                    Los datos del candidato fueron creados de manera exitosa
                  </>
              );

              API.post("/applications/create", formData[0])
                  .then(function (response) {
                    if (response.status === 200) {
                      let msg = "";
                      msg = <>Los datos del candidato fueron creados de manera exitosa</>;
                      setMessage(msg);
                      setHttpStatus(response.status);
                      setIsSentData(false);
                      handleShow();
                    }
                  })
                  .catch((error) => {
                    if (error.response) {
                      if (error.response.status === 500) {
                        let msg = "";
                        msg = (
                            <>
                              Hubo un problema al registrar los datos, por favor intenta
                              nuevamente
                            </>
                        );
                        setMessage(msg);
                        setHttpStatus(error.response.status);
                        setIsSentData(false);
                        handleShow();
                      } else if (error.response.status === 400) {
                        let msg = <>{error.response.data.message}</>;
                        setMessage(msg);
                        setHttpStatus(error.response.status);
                        setIsSentData(false);
                        handleShow();
                      }
                    } else {
                      let msg = (
                          <>
                            Hubo un problema al conectar con el servidor, verifica tu conexión
                            a Internet e intenta nuevamente
                          </>
                      );
                      setMessage(msg);
                      setIsSentData(false);
                      handleShow();
                    }
                  });
            } else {
              msg = (
                  <>
                    Hubo en error al cargar el CV, pero Los datos de candidato
                    fueron creados de manera exitosa
                  </>
              );
            }
            setMessage(msg);
            setHttpStatus(response.status);
            setIsSentData(false);
            handleShow();
          })
          .catch((error) => {
            let msg = "";
            msg = (
                <>
                  Hubo en error al cargar el CV, pero Los datos de candidato
                  fueron creados de manera exitosa
                </>
            );
            setMessage(msg);
            setHttpStatus(500);
            setIsSentData(false);
            handleShow();
          });

    }else{
      API.post("/applications/create", formData[0])
          .then(function (response) {
            if (response.status === 200) {
              let msg = "";
              msg = <>Los datos del candidato fueron creados de manera exitosa</>;
              setMessage(msg);
              setHttpStatus(response.status);
              setIsSentData(false);
              handleShow();
            }
          })
          .catch((error) => {
            if (error.response) {
              if (error.response.status === 500) {
                let msg = "";
                msg = (
                    <>
                      Hubo un problema al registrar los datos, por favor intenta
                      nuevamente
                    </>
                );
                setMessage(msg);
                setHttpStatus(error.response.status);
                setIsSentData(false);
                handleShow();
              } else if (error.response.status === 400) {
                let msg = <>{error.response.data.message}</>;
                setMessage(msg);
                setHttpStatus(error.response.status);
                setIsSentData(false);
                handleShow();
              }
            } else {
              let msg = (
                  <>
                    Hubo un problema al conectar con el servidor, verifica tu conexión
                    a Internet e intenta nuevamente
                  </>
              );
              setMessage(msg);
              setIsSentData(false);
              handleShow();
            }
          });
    }

  };

  const handleSubmit = (e) => {
    e.preventDefault();
    checkEmpty();
  };

  const handleClick = (e) => {
    file.current.click();
  };

  useEffect(() => {
    getSearches();
    getListUniversity();
  }, []);

  useEffect(() => {
    if (isSentData) {
      sendData();
    } else {
      setIsSentData(false);
    }
  }, [isSentData]);

  return (
    <>
      <Header />
      <Menu />
      {
         <div className="content-formulario ">
         <div className="formulario newapplicant">
           <form method="POST" onSubmit={handleSubmit}>
             <select
               onChange={(e) => checkvalue(e.target.name, e.target.value)}
               name="id_search"
               id="asignar"
               value={idSearch}
             >
               <option value="">Asignar búsqueda</option>
               {searches.map((item, i) => (
                 <option key={i} value={item.search_id}>
                   {item.search_id} - {item.position_name}
                 </option>
               ))}
             </select>
             <div
               className={`alert alert-danger mt-3 ${
                 idSearchError == 1 ? "" : "d-none"
               }`}
             >
               {idSearchWarning}
             </div>
 
            
             
                  <div className="content-datos">
                  <div className="content-linea">
                    <div className="input-error">
                      <input
                        name="firstName"
                        value={firstName}
                        type="text"
                        id="primer_nombre"
                        onChange={(e) => checkvalue(e.target.name, e.target.value)}
                        placeholder="Nombre"
                      />
                      <div
                        className={`alert alert-danger mt-3 ${
                          firstNameError == 1 ? "" : "d-none"
                        }`}
                      >
                        {firstNameWarning}
                      </div>
                    </div>
                    <div className="input-error">
                      <input
                        name="lastName"
                        value={lastName}
                        type="text"
                        id="primer_apellido"
                        onChange={(e) => checkvalue(e.target.name, e.target.value)}
                        placeholder="Apellido"
                      />
                      <div
                        className={`alert alert-danger mt-3 ${
                          lastNameError == 1 ? "" : "d-none"
                        }`}
                      >
                        {lastNameWarning}
                      </div>
                    </div>
                  </div>
                  <div className="content-linea">
                    <div className="input-error">
                      <select
                        name="education"
                        style={{ marginTop: "5px" }}
                        value={education}
                        onChange={(e) => checkvalue(e.target.name, e.target.value)}
                      >
                        {OptionsList.Grado.map((element, key) => {
                          return (
                            <option value={key === 0 ? "" : element} key={key}>
                              {element}
                            </option>
                          );
                        })}
                      </select>
    
                      <div
                        className={`alert alert-danger mt-3 ${
                          educationError ? "" : "d-none"
                        }`}
                      >
                        {educationWarning}
                      </div>
                    </div>
    
                    <div className="input-error">
                      <input
                        name="dni"
                        value={dni}
                        type="text"
                        id="dni"
                        onChange={(e) => checkvalue(e.target.name, e.target.value)}
                        placeholder="DNI"
                      />
                      <div
                        className={`alert alert-danger mt-3 ${
                          dniError == 1 ? "" : "d-none"
                        }`}
                      >
                        {dniWarning}
                      </div>
                    </div>
                  </div>
                  <div className="content-linea">
                    <div className="input-error">
                      <select
                        name="title"
                        style={{ marginTop: "5px" }}
                        value={title}
                        onChange={(e) => checkvalue(e.target.name, e.target.value)}
                      >
                        {OptionsList.Carreras.map((element, key) => {
                          return (
                            <option value={key === 0 ? "" : element} key={key}>
                              {element}
                            </option>
                          );
                        })}
                      </select>
    
                      <div
                        className={`alert alert-danger mt-3 ${
                          titleError ? "" : "d-none"
                        }`}
                      >
                        {titleWarning}
                      </div>
                    </div>
    
                    <div className="input-error">
                      <input
                        name="email"
                        value={email}
                        type="text"
                        id="mail"
                        onChange={(e) => checkvalue(e.target.name, e.target.value)}
                        placeholder="E-mail"
                      />
                      <div
                        className={`alert alert-danger mt-3 ${
                          emailError == 1 ? "" : "d-none"
                        }`}
                      >
                        {emailWarning}
                      </div>
                    </div>
                  </div>
    
                  <div className="content-linea">
                    <div className="input-error">
                      <select
                        style={{ marginTop: "5px" }}
                        name="university"
                        value={university}
                        onChange={(e) => checkvalue(e.target.name, e.target.value, e.target.options[e.target.selectedIndex].dataset.value )}
                      > <>
                        <option value="">Universidades</option>
                        {
                          listUniversity.map((element, key) => {
   
                            return (
                              <option value={element.value} key={element.value} data-value={element.name}>
                                {element.name}
                              </option>
                             
                            )
                            
                          })
                        }
                        </>
                      </select>
    
                      <div
                        className={`alert alert-danger mt-3 ${
                          universityError ? "" : "d-none"
                        }`}
                      >
                        {universityWarning}
                      </div>
                    </div>
    
                    <div className="input-error">
                      <input
                        name="phoneNumber"
                        value={phoneNumber}
                        type="text"
                        id="telefono"
                        onChange={(e) => checkvalue(e.target.name, e.target.value)}
                        placeholder="Telefono de contacto"
                      />
                      <div
                        className={`alert alert-danger mt-3 ${
                          phoneNumberError == 1 ? "" : "d-none"
                        }`}
                      >
                        {phoneNumberWarning}
                      </div>
                    </div>
                  </div>
    
                  <div className="content-linea">
                    <div className="input-error">
                      <input
                        name="lastJobTitle"
                        value={lastJobTitle}
                        type="text"
                        id="ultima-pocision"
                        onChange={(e) => checkvalue(e.target.name, e.target.value)}
                        placeholder="Nombre de última posicion"
                      />
                      <div
                        className={`alert alert-danger mt-3 ${
                          lastJobTitleError == 1 ? "" : "d-none"
                        }`}
                      >
                        {lastJobTitleWarning}
                      </div>
                    </div>
                    <div className="input-error">
                      <input
                        name="business"
                        value={business}
                        type="text"
                        id="business"
                        onChange={(e) => checkvalue(e.target.name, e.target.value)}
                        placeholder="Nombre de la empresa"
                      />
                      <div
                        className={`alert alert-danger mt-3 ${
                          businessError == 1 ? "" : "d-none"
                        }`}
                      >
                        {businessWarning}
                      </div>
                    </div>

                    
                  </div>
                  <div className="content-linea">
                    <div className="input-error">
                      <input
                        name="birthday"
                        value={birthday}
                        type="text"
                        id="fecha-nac"
                        onChange={(e) => checkvalue(e.target.name, e.target.value)}
                        placeholder="Fecha de nacimiento (dd/mm/aaaa)"
                      />
                      <div
                        className={`alert alert-danger mt-3 ${
                          birthdayError == 1 ? "" : "d-none"
                        }`}
                      >
                        {birthdayWarning}
                      </div>
                    </div>
                    <div className="input-error">
                      <input
                        name="experience"
                        value={experience}
                        type="text"
                        id="experiencia"
                        onChange={(e) => checkvalue(e.target.name, e.target.value)}
                        placeholder="Años de experiencia"
                      />
                      <div
                        className={`alert alert-danger mt-3 ${
                          experienceError == 1 ? "" : "d-none"
                        }`}
                      >
                        {experienceWarning}
                      </div>
                    </div>
                  </div>
                  <div className="content-linea">
                    <div className="input-error">
                      <input
                        name="personInCharge"
                        value={personInCharge}
                        type="text"
                        id="a-cargo"
                        onChange={(e) => checkvalue(e.target.name, e.target.value)}
                        placeholder="Gente a cargo"
                      />
                      <div
                        className={`alert alert-danger mt-3 ${
                          personInChargeError == 1 ? "" : "d-none"
                        }`}
                      >
                        {personInChargeWarning}
                      </div>
                    </div>
                    <div className="input-error">
                    <span>¿Cómo encontró esta postulación?</span>
                    <select
                      name="whereFind"
                      value={whereFind}
                      onChange={(e) => checkvalue(e.target.name, e.target.value)}
                    >
                      <option value="">Seleccionar</option>
                      {whereFindOptions.map((wf, index) => <option key={index} value={wf.value}>{wf.text}</option>)}
                    </select>
                    <div
                      className={`alert alert-danger mt-3 ${
                        whereFindError ? "" : "d-none"
                      }`}
                    >
                      {whereFindWarning}
                    </div>
                  </div>
                  </div>
                <div className="content-linea">

                  {whereFind === "Referido" && (
                    <div className="input-error">
                      <span>Complete con el nombre del empleado de Balanz que lo refirió</span>
                      <input
                        name="friendRecomended"
                        value={friendRecomended}
                        type="text"
                        id="a-cargo"
                        onChange={(e) =>
                          checkvalue(e.target.name, e.target.value)
                        }
                        placeholder="Nombre de tu referencia"
                      />
                      {/* <div className={`alert alert-danger mt-3 ${personInChargeError ? "" : "d-none"}`}>{personInChargeWarning}</div> */}

                      <div
                        className={`alert alert-danger mt-3 ${
                          friendRecomendedError ? "" : "d-none"
                        }`}
                      >
                        {friendRecomendedWarining}
                      </div>
                    </div>
                  )}

                  {/* {whereFind === "Universidad" && (
                    <div className="input-error">
                      <span>Fuente</span>
                     
                      <select
                        style={{ marginTop: "5px" }}
                        name="sourceDetail"
                        value={sourceValue}
                        onChange={(e) => checkvalue(e.target.name, e.target.value, e.target.options[e.target.selectedIndex].dataset.value )}
                      > <>
                        <option value="">Universidades</option>
                        {
                          listUniversity.map((element, key) => {
   
                            return (
                              <option value={element.value} key={element.value} data-value={element.name}>
                                {element.name}
                              </option>
                             
                            )
                            
                          })
                        }
                        </>
                      </select>
                      <div
                        className={`alert alert-danger mt-3 ${
                          sourceDetailError ? "" : "d-none"
                        }`}
                      >
                        {sourceDetailWarning}
                      </div>
                    </div>
                  )} */}

                  {whereFind === "Universidad" && (
                    <div className="input-error">
                     
                      <span>Fuente</span>
                      <select
                        style={{ marginTop: "5px" }}
                        name="sourceDetail"
                        value={sourceValue}
                        onChange={(e) => checkvalue(e.target.name, e.target.value, e.target.options[e.target.selectedIndex].dataset.value )}
                      > <>
                        <option value="">Universidades</option>
                        {
                          listUniversity.map((element, key) => {
   
                            return (
                              <option value={element.value} key={element.value} data-value={element.name}>
                                {element.name}
                              </option>
                             
                            )
                            
                          })
                        }
                        </>
                      </select>
                      <div
                        className={`alert alert-danger mt-3 ${
                          sourceDetailError ? "" : "d-none"
                        }`}
                      >
                        {sourceDetailWarning}
                      </div>
                    </div>
                  )}
                </div>
                  <div className="content-linea">
                    <div className="input-error"></div>
                    <div className="opciones">
                      {/* <div onClick={(e) => checkvalue('highlighted', !highlighted)} className={`marcar ${highlighted == 1 ? 'marcado' : ''}`}>MARCAR COMO CANDIDATO GUARDADO <img src={`${highlighted == 1 ? checkActive : check }`} alt="" /></div> */}
                      <button
                        type="button"
                        onClick={(e) => checkvalue(e.target.name, e.target.value)}
                        value={highlighted}
                        name="highlighted"
                        className={`resaltar ${
                          highlighted === 1 ? "resaltado" : ""
                        }`}
                      >
                        RESALTAR CANDIDATO{" "}
                        <img
                          src={`${highlighted === 1 ? favoritoActive : favorito}`}
                          alt=""
                          style={{pointerEvents:"none"}}
                        />
                      </button>
                    </div>
                  </div>
                </div>
                
                
               
             <div className="botones">
               <span>* Todos los campos son obligatorios</span>
               <span>Búsqueda laboral equitativa. El empleador sólo podrá solicitarle la información {<><br/> </>}
               estrictamente necesaria para el desempeño en el trabajo que se ofrece. Ley N°6471.</span>
               <input
                 accept=".pdf,.docx,.doc"
                 className="d-none"
                 ref={file}
                 name="selectedFile"
                 type="file"
                 onChange={(e) => checkvalue(e.target.name, e.target.files[0])}
               />
               <div onClick={(e) => handleClick()} className="curriculum">
                 <img src={curriculum} alt="CV" />
                 ADJUNTAR CV EN WORD / PDF
               </div>
               <span>{nameFile}</span>
               <div
                 className={`alert alert-danger mt-3 ${
                   selectedFileError == 1 ? "" : "d-none"
                 }`}
               >
                 {selectedFileWarning}
               </div>
               {
                isSentData && (
                  <button
                 type="submit"
                 className="crear"
                disabled={true}
               >
                 Cargando...
               </button>
                )

               }
               {
                  !isSentData && (
                    <button
                    type="submit"
                    className="crear"
                    >
                      CREAR NUEVO CANDIDATO
                    </button>
                  )
               }
             </div>
           </form>
         </div>
       </div>
       
         
      }

      <Modal
        show={show}
        backdrop={"static"}
        className="modal_candidato"
        onHide={handleClose}
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>{message}</Modal.Body>
        <Modal.Footer>
          {httpStatus === 200 ? (
            <Link to="/busqueda">
              <Button variant="primary">Ir a Búsquedas</Button>
            </Link>
          ) : (
            <Button onClick={handleClose}>Cerrar</Button>
          )}
        </Modal.Footer>
      </Modal>
      <Footer />
    </>
  );
};

export default NewApplicant;
